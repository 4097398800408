import React from "react"

import ahahah from "../static/ah-ah-ah.webp"
import ahahahaudio from "../static/ah-ah-ah.mp3"

const IndexPage = () => (
    <>
      <audio controls autoPlay loop src={ahahahaudio} type="audio/mp3" />
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: `800px`}}>
        <img src={ahahah} alt="ah-ah-ah" />
      </div>
    </>

)

export default IndexPage
